import { createTheme, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, {
    lazy, Suspense
  } from 'react';
  import {
    Switch,
    Route,
    Redirect
  } from 'react-router-dom';
  import LoadingScreen from './components/LoadingScreen';
  import DateFnsUtils from '@date-io/date-fns';
  
  type Routes = {
    exact?: boolean;
    path?: string | string[];
    component?: any;
  }[];
  

  const theme = createTheme({
    palette: {
      primary: {
        main: '#10006F',
      },
    },
  });

  
  export const renderRoutes = (routesConfig: Routes = []): JSX.Element => (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Suspense fallback={<LoadingScreen />}>      
          <Switch>
              {routesConfig.map((route, i) => {
                const Component = route.component;
      
                return (
                  <Route
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    render={(props) => (
                      <Component {...props} />
                    )}
                  />
                );
              })}
            </Switch>
        </Suspense>
      </MuiPickersUtilsProvider>      
    </ThemeProvider>    
  );
  
  const routes: Routes = [
    {
      exact: true,
      path: '/404',
      component: lazy(() => import('./NotFound'))
    },
    {
      exact: true,
      path: '/',
      component: lazy(() => import('./VisitorForm'))
    },    
    {
      exact: true,
      path: '/contractor',
      component: lazy(() => import('./ContractorForm'))
    },
    {
      exact: true,
      path: '/reports',
      component: lazy(() => import('./Reports'))
    },
    {
      path: '*',
      component: () => <Redirect to="/404" />
    }  
  ];
  
  export default routes;
  