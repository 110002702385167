import React, { useEffect } from 'react';
import type { FC } from 'react';
import NProgress from 'nprogress';
import {
  Box,
  CircularProgress,
} from '@material-ui/core';

const LoadingScreen: FC = () => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box textAlign='center'>
      <CircularProgress />
    </Box>    
  );
};

export default LoadingScreen;
