import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';
import routes, { renderRoutes } from './routes';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

function Main() {
  return (
      <div className="main-container">
          <Router history={history}>
            {renderRoutes(routes)}
          </Router>      
      </div>        
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
  document.getElementById('visitor-registration')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
